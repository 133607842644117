@font-face {
  font-family: 'MuseoSans';
  src: local('MuseoSans'), url('../fonts/MuseoSansRounded500.otf'),
    url('../fonts/MuseoSansRounded700.otf');
}

@font-face {
  font-family: 'MuseoSlab';
  src: local('MuseoSlab'), url('../fonts/MuseoSlab500.otf');
}
